<template>
  <div class="flex relative" v-click-outside="reset">
    <button
      @click="openDropdown"
      class="border py-2 px-4 flex group items-center hover:border-blue-700 hover:text-blue-700 cursor-pointer border-primaryBlue text-xs rounded-md text-primaryBlue font-medium"
    >
      Actions
      <svg
        class="w-4 h-4 ml-2 group-hover:text-blue-700"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.24106 7.7459C4.53326 7.44784 4.99051 7.42074 5.31272 7.66461L5.40503 7.7459L12 14.4734L18.595 7.7459C18.8872 7.44784 19.3444 7.42074 19.6666 7.66461L19.7589 7.7459C20.0511 8.04396 20.0777 8.51037 19.8386 8.83904L19.7589 8.93321L12.582 16.2541C12.2898 16.5522 11.8325 16.5793 11.5103 16.3354L11.418 16.2541L4.24106 8.93321C3.91965 8.60534 3.91965 8.07376 4.24106 7.7459Z"
          fill="#253B95"
        />
      </svg>
    </button>
    <div
      v-show="showOptions && currentTagIndex === index"
      class="bg-white text-left py-2 rounded-md shadow-md border border-gray-200 absolute mt-10 z-20 w-32"
    >
      <div v-for="(actionType, id) in actions" :key="id">
        <div
          @click="emitRecordAndAction(actionType.name, index)"
          class="py-2 px-6 hover:bg-blue-700 hover:text-white"
        >
          {{ actionType.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showOptions: false,
      currentTagIndex: -1,
    };
  },
  props: {
    index: {
      type: Number,
    },
    actions: {
      type: Array,
      default: () => {
        return [
          { name: "edit", label: "Edit" },
          { name: "delete", label: "Delete" },
        ];
      },
    },
  },
  methods: {
    emitRecordAndAction(action, index) {
      this.currentTagIndex = index;
      console.log("emitting from MultiAction: ", action);
      this.$emit("select", action);
    },
    openDropdown() {
      this.showOptions = !this.showOptions;
      this.currentTagIndex = this.index;
    },
    reset() {
      this.currentTagIndex = -1;
      this.showOptions = !this.showOptions;
    },
  },
};
</script>

<style scoped></style>
