<template>
  <div>
    <trac-loading v-if="isLoading" />
    <main class="md:ml-12">
      <trac-modal v-if="bulkUploadModalCtrl" @close="resetBulkUploadData">
        <div class="text-center my-10 mx-10">
          <h3 class="font-bold mb-4">Import product</h3>
          <p class="text-xs mb-4">
            <a
              href="https://s3-us-west-1.amazonaws.com/tractionapp-products/pkg/app/uploads/6594325149036885435Upload-Template.xlsx"
              download
              class="text-primaryBlue font-semibold cursor-pointer"
              >Download our bulk upload product template</a
            >, fill it, then upload your items below if you don’t have the
            template. It is best to add all the details before upload.
          </p>

          <div
            class="cursor-pointer border-2 border-dotted bg-transparentBackground p-10 rounded-md flex items-center justify-center mx-10"
          >
            <img
              src="./../../assets/svg/product_bulk_upload_note.svg"
              alt=""
              class=""
              width="30"
            />
            <input
              type="file"
              name=""
              @input="selectBulkProductUploadDoc"
              id=""
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              class="hidden"
              ref="bulkUploadCtrl"
            />
            <p class="text-xs">
              Drag and drop your file here or
              <span
                class="text-primaryBlue font-semibold"
                @click="$refs['bulkUploadCtrl'].click()"
                >Select it from your computer</span
              >
            </p>
          </div>

          <div class="mt-10">
            <div class="" v-if="currenrtBulkUploadStage === 0">
              <p class="text-xs mb-5" v-if="bulkUploadData">
                {{ bulkUploadData.length }} product(s) to be uploaded.
              </p>
              <!-- Button -->
              <trac-button
                @button-clicked="uploadBulkProducts"
                :disabled="bulkUploadData === null"
                class="px-20"
                >Upload</trac-button
              >
              <!-- Error response text -->
              <!-- <p v-if="!bulkUploadRes.status" class="text-secondaryRed text-xs">
              The file you are trying to upload is not the supported format,
              Kindly download the product template above.
            </p> -->
            </div>
            <!-- Progress bar -->
            <!-- <div class="" v-if="currenrtBulkUploadStage === 1">
            <p
              class="text-xs text-primaryGray mb-2 text-left ml-10 font-medium"
            >
              Uploading... 25%
            </p>
            <div
              class="
                bg-transparentBackground
                rounded rounded-l-none
                overflow-hidden
                mx-10
              "
            >
              <div class="py-1 bg-primaryBlue w-4/12"></div>
            </div>
            <div class="flex justify-end mt-2 mr-10">
              <button class="bg-none text-xs text-primaryGray">
                Cancel Upload
              </button>
            </div>
          </div> -->
            <!-- Completed upload -->
            <div
              class="text-xs mt-4 text-primaryGray"
              v-if="currenrtBulkUploadStage === 1"
            >
              <!-- <div v-if="bulkUploadRes.status" class="text-xs text-left mx-10 text-primaryGray">
              <p class="">
                Number of successful product upload:
                <span class="font-bold">{{
                  bulkUploadRes.data.SuccessfulUploads
                }}</span>
              </p>
              <p class="">
                Number of failed upload:
                <span class="font-bold">{{
                  bulkUploadRes.data.FailedUploads
                }}</span>
              </p>
            </div> -->
              <p
                v-if="bulkUploadRes.data.FailedUploads > 0"
                class="mt-4 font-bold"
              >
                Reasons for failing
              </p>
              <p class="">
                {{ bulkUploadRes.message }}
              </p>
              <!-- v-if="bulkUploadRes.data.FailedUploads > 0" -->
              <button
                v-if="1"
                @click="
                  $router.push(
                    '/products-upload-failed/' + bulkUploadRes.data.BatchId
                  )
                "
                class="bg-none text-primaryBlue inline-block mt-8 font-semibold"
              >
                Click to see upload progress
              </button>
              <trac-button
                class="mt-8 px-10"
                v-else
                @button-clicked="resetBulkUploadData"
              >
                Done
              </trac-button>
            </div>
          </div>
        </div>
      </trac-modal>

      <!-- ReAssign Window -->
      <trac-modal v-if="showReassign" @close="showReassign = false">
        <div class="mx-10 my-8 text-center flex flex-col items-center gap-y-8">
          <span class="font-semibold text-2xl leading-tight"
            >You will have to reassign product before you delete category</span
          >
          <div class="flex flex-row w-8/12 mx-auto justify-center">
            <trac-button class="uppercase" variant="primary">
              Re-assign product
            </trac-button>
          </div>
        </div>
      </trac-modal>

      <!-- Message to show when SW Sync is in progress -->
      <div v-if="isACleanSlate">
        <trac-dual-sync
          :status="dualSyncStatus"
          :progress="progress"
          @onResync="reSync"
        ></trac-dual-sync>
      </div>

      <div v-else>
        <div class="wrapper">
          <div class="flex flex-row justify-between">
            <ul class="flex-col mt-12 mb-24">
              <li>Products</li>
              <li class="font-bold mt-4 text-2xl">
                Total: {{ totalRecords }} Products
              </li>
            </ul>
            <ul v-if="isOnline" class="flex flex-row mt-32 mb-8 items-end">
              <li class="mr-5">
                <trac-button
                  variant="outline"
                  @button-clicked="$router.push({ name: 'UploadedBatches' })"
                >
                  UPLOADED PRODUCTS
                </trac-button>
              </li>
              <li class="mr-5">
                <trac-button
                  variant="outline"
                  @button-clicked="$router.push({ name: 'BulkProductsUpload' })"
                >
                  IMPORT PRODUCT
                </trac-button>
              </li>
            </ul>
          </div>

          <!-- <div class="">
        <p class="text-xs text-gray-500 text-right my-2">
          <span class="text-red-600">*</span>
          Offline Products
        </p>
      </div> -->
          <div
            class="bg-white py-4 rounded-lg big-shadow h-screen md:overflow-scroll"
            @scroll="doDelayedSearch($event)"
          >
            <div class="mt-8 mb-5 mx-8 gap-4 flex flex-row items-center">
              <div class="w-1/4 md:w-1/3">
                <div
                  class="flex flex-row bg-white items-center rounded big-shadow justify-between"
                >
                  <span class="text-xs font-light text-primaryGray w-full">
                    <input
                      class="w-full py-2 px-4 outline-none"
                      type="text"
                      name=""
                      id=""
                      v-model="params.keyword"
                      placeholder="Search product..."
                    />
                    <!-- v-model.trim="searchedProduct" -->
                  </span>
                  <img src="../../assets/svg/Search_icon.svg" class="mr-3" />
                </div>
              </div>
              <div class="md:flex-1"></div>
              <!-- <trac-dropdown
            @input="selectCategory"
            :title="'Select categories'"
            class="md:mr-5 md:w-48"
            checkBoxItems
            :selectedData="getSelectedCats"
            :menuItems="categories"
            @add-to-filter="filterCategories"
          ></trac-dropdown> -->
              <trac-dropdown
                @input="selectStockLevel"
                class="sm:w-1/4 md:1/4"
                :title="stockLevel[0].name"
                :menuItems="stockLevel"
              ></trac-dropdown>
              <button
                @click="searchSelectedStockLevel"
                :disabled="searchingStatus"
                class="px-4 py-3 text-xs rounded-md text-white button-style font-semibold"
              >
                <span v-if="!searchingStatus">Search</span>
                <span v-else>Searching...</span>
              </button>
              <div
                v-if="
                  permissionsModules[4]['parent']['permissionsForUser'][
                    'createProduct'
                  ]
                "
              >
                <trac-button
                  v-if="isOnline"
                  @button-clicked="gotoProductsInputPage"
                  class="flex-initial"
                  >CREATE PRODUCT
                </trac-button>
              </div>
            </div>
            <div class="mx-8 mb-8 border rounded-lg overflow-x-scroll">
              <table class="w-full table-auto">
                <thead class="bg-blue-100">
                  <tr class="">
                    <th
                      class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
                    >
                      <input type="checkbox" />
                    </th>
                    <th
                      class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
                    >
                      Item
                    </th>
                    <th
                      class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
                    >
                      SKU
                    </th>
                    <th
                      class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
                    >
                      Category
                    </th>
                    <th
                      class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
                    >
                      Qty in Stock
                    </th>
                    <th
                      class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
                    >
                      Price
                    </th>
                    <th
                      class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
                      v-if="
                        permissionsModules[4]['parent']['permissionsForUser'][
                          'deleteProduct'
                        ]
                      "
                    ></th>
                  </tr>
                </thead>
                <tbody v-if="sortedProducts.length > 0">
                  <tr
                    :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                    v-for="(product, index) in products"
                    class="cursor-pointer hover:bg-gray-200"
                    :key="index"
                  >
                    <td class="text-xs whitespace-no-wrap p-4">
                      <input type="checkbox" />
                    </td>
                    <td
                      class="text-xs p-4 font-bold capitalize"
                      @click="gotoProductsDetailsPage(product)"
                    >
                      {{ product.title }}
                    </td>
                    <td
                      class="text-xs whitespace-no-wrap p-4 font-medium"
                      @click="gotoProductsDetailsPage(product)"
                    >
                      {{ product.barcode === "" ? "N/A" : product.barcode }}
                    </td>
                    <td
                      class="text-xs whitespace-no-wrap p-4 font-medium capitalize"
                      @click="gotoProductsDetailsPage(product)"
                    >
                      {{ product.categories?.name || "N/A" }}
                    </td>
                    <td
                      class="text-xs whitespace-no-wrap p-4 font-medium"
                      @click="gotoProductsDetailsPage(product)"
                    >
                      {{ getStockQuantity(product) }}
                    </td>
                    <td
                      class="text-xs whitespace-no-wrap p-4 font-medium"
                      v-if="product.variations"
                      @click="gotoProductsDetailsPage(product)"
                    >
                      {{ split(product.priceRange, "-")[0] | formatPrice }}
                      <span v-if="split(product.priceRange, '-')[1]">
                        - {{ split(product.priceRange, "-")[1] | formatPrice }}
                      </span>
                    </td>
                    <td
                      class="text-xs whitespace-no-wrap p-4 font-medium"
                      @click="gotoProductsDetailsPage(product)"
                      v-else
                    >
                      {{ product.salePrice | formatPrice }}
                    </td>
                    <td
                      class="text-xs whitespace-no-wrap p-4 text-center font-medium mr-12"
                      v-if="
                        permissionsModules[4]['parent']['permissionsForUser'][
                          'deleteProduct'
                        ]
                      "
                    >
                      <MoreActionButton
                        :index="index"
                        @select="selectAction($event, product)"
                      />
                      <!-- <trac-button
                      @button-clicked="toggleDeleteModal(product)"
                      class="text-secondaryRed"
                      variant="small"
                      >Delete
                    </trac-button> -->
                    </td>
                    <trac-modal v-if="deleteModal" @close="deleteModal = false">
                      <div
                        v-if="!productDeleted"
                        class="mx-10 my-8 text-center"
                      >
                        <span class="font-semibold text-xl"
                          >Are you sure you want to delete
                          {{ productToDelete.title }}?</span
                        >
                        <div
                          class="flex flex-row w-8/12 justify-between mx-auto mt-16"
                        >
                          <trac-button
                            variant="outline"
                            @button-clicked="deleteModal = false"
                            >Cancel
                          </trac-button>
                          <trac-button
                            class="text-secondaryRed border-secondaryRed"
                            variant="outline"
                            @button-clicked="deleteProduct(productToDelete)"
                            >Delete
                          </trac-button>
                        </div>
                      </div>
                      <div v-else class="mx-20 my-10 text-center">
                        <div class="flex-column">
                          <img
                            class="mx-auto mb-8"
                            src="../../assets/svg/trash.svg"
                          />
                          <span class="font-semibold text-xl"
                            >You have successfully deleted
                            {{ productToDelete.title }}</span
                          >
                        </div>
                      </div>
                    </trac-modal>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="7">
                      <trac-center-data>
                        <div
                          class="h-40 flex items-center text-lg text-gray-600"
                        >
                          {{
                            params.keyword.length > 0 && products.length > 0
                              ? "No match found."
                              : "No product added yet."
                          }}
                        </div>
                      </trac-center-data>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="!isLoading" class="flex justify-center mt-4">
              <trac-button
                :disabled="products.length >= totalRecords"
                @button-clicked="loadMoreProducts"
              >
                {{
                  products.length >= totalRecords
                    ? "No More Items"
                    : "Load More"
                }}
              </trac-button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import AdvancedSearch from "../../offline-module/advancedSearch";
import { cleanSlate } from "../../offline-module/offline.store";
import MoreActionButton from "../../components/MultiActionButton.vue";

import {
  DELETE_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import DeleteProduct from "./DeleteProduct";
import Pagination from "vue-pagination-2";
import { apiUrl } from "../../environment/environment";
import { eventBus } from "./../../main";
import { permissionsModules } from "./../settings/permission";
import readXlsxFile from "read-excel-file";

const debounceEvent =
  (callback, time = 250, interval) =>
  (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => callback(...args), time);
  };

const schema = {
  "Title (Compulsory)": {
    prop: "title",
    type: String,
    required: true,
  },
  "Category (Compulsory)": {
    prop: "category",
    type: String,
    required: true,
  },
  "Price (Compulsory)": {
    prop: "price",
    type: Number,
    required: true,
  },
  "Description (Optional)": {
    prop: "description",
    type: String,
    required: true,
  },
  Manage_Stock: {
    prop: "manage_stock",
    required: true,
  },
  stock: {
    prop: "stock",
    type: Number,
    required: true,
  },
  "Store_name (Compulsory)": {
    prop: "store_name",
    type: String,
    required: true,
  },
};

export default {
  components: { DeleteProduct, Pagination, MoreActionButton },
  computed: {
    isACleanSlate() {
      return cleanSlate.moduleList["products"] === true;
    },
    sortedProducts() {
      return (
        this.filteredProducts ||
        []
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .filter((prod) =>
            this.selectedCategoriesFiltered.includes(
              prod.category.toLowerCase()
            )
          )
      );
    },
    getSelectedCats() {
      return this.selectedCategoriesFiltered;
    },
  },
  data() {
    return {
      permissionsModules,
      page: 1,
      adv: new AdvancedSearch({
        BusinessId: GET_USER_BUSINESS_ID(),
        offlineManager: this.$GlobalOfflineManager,
      }),
      selectedStockLevel: "all",
      searchingStatus: false,
      totalRecords: 0,
      currenrtBulkUploadStage: 0,
      isLoading: false,
      bulkUploadModalCtrl: false,
      bulkUploadRes: false,
      products: [],
      searchedData: "",
      filteredProducts: null,
      bulkUploadFile: null,
      bulkUploadData: null,
      deleteModal: false,
      showingProducts: [],
      selectedCategoriesFiltered: [],
      selectedCategories: [],
      productDeleted: false,
      productToDelete: null,
      doneASearch: false,
      showReassign: false,
      selectedProductHolder: null,
      progress: {
        name: "",
        recordsDone: 0,
        total: 0,
      },
      stockLevel: [
        { name: "All Stock Levels", value: "" },
        { name: "In Stock", value: "in-stock" },
        { name: "Out Of Stock", value: "out-of-stock" },
      ],
      categories: null,
      params: {
        productType: "simple",
        page: 1,
        limit: 20,
        keyword: "",
      },
      timeout: null,
    };
  },
  async created() {
    await this.NetworkCheck();
    // this.totalRecords = await this.adv.getTotalRecords("products");
    DELETE_LOCAL_DB_DATA("product-details");
    this.dualSyncStatus = "syncing";
    // this.loadAllRecordsFromOffline();
    this.loadProducts();

    // Setting products to show an initial amount
    this.showingProducts = this.sortedProducts.slice(0, 30);

    // this.isLoading = false;
  },
  watch: {
    SWData(newValue, oldValue) {
      console.log("Products -> SWData, ", newValue);
      if (newValue.type === "dualsync") {
        // this.dualSyncStatus = "syncing";
        cleanSlate.moduleList["products"] = false;
        // this.loadAllRecordsFromOffline();
      }

      if (newValue.type === "progress" && newValue.data.name === "products") {
        this.progress = newValue.data;
      }
    },
    "params.keyword"(val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.params.keyword = val;
        this.loadProducts();
      }, 500);
    },
  },
  methods: {
    split(val, by) {
      return val.split(by);
    },
    loadMoreProducts() {
      this.params = {
        ...this.params,
        page: 1,
        limit: this.params.limit + 10,
      };

      this.loadProducts();
    },
    getStockQuantity(product) {
      if (product.hasVariant)
        return product.variations?.reduce((acc, v) => acc + v.stockQuantity, 0);
      return (
        product.stockQuantity ||
        (product.storeStock?.reduce((acc, v) => acc + v.stockQuantity, 0) ?? 0)
      );
    },
    loadProducts() {
      /**
       * Create a dispatch for 'FETCH_PRODUCTS_V2'. from the response called 'res',
       * if res.status is true, assign res.data.products to a const called 'result'
       * and console log it.
       * else, emit a trac-alert event with the message from res.message'
       */
      this.isLoading = true;
      this.$store
        .dispatch("FETCH_PRODUCTS_V2", {
          params: this.params,
          includeVariants: true,
          queryText: "variant",
        })
        .then((res) => {
          if (res.status) {
            this.totalRecords = res.data.total_count;
            this.products = res.data.products || [];
            this.filteredProducts = [...this.products];
          } else {
            eventBus.$emit("trac-alert", { message: res.message });
          }
          this.isLoading = false;
        });
    },
    selectAction(action, product) {
      switch (action) {
        case "edit":
          this.$router.push({
            name: "EditProduct",
            params: {
              id: product.id,
            },
          });
          return;
        case "delete":
          this.toggleDeleteModal(product);
          return;

        default:
          return;
      }
      // (this.selectedProductHolder = product), (this.showReassign = true);
    },
    doDelayedSearch(e) {
      debounceEvent(this.checkScroll(e), 250);
    },
    selectFile() {
      this.bulkUploadData = null;
      this.currenrtBulkUploadStage = 0;
    },
    async doSearch() {
      this.doneASearch = true;
      const searchResults = await this.doFilter();
      // TODO: create a vue variable (boolean) that gets set to true (default is fault)
      // we can then use this to track pagination for cases where text isn't specified
      // but stock level has been set
      this.products = searchResults;
    },
    async doFilter(currentIndex = 0) {
      let results = [];
      this.searchingStatus = true;
      const where = {};

      console.log("selected stock level:", this.selectedStockLevel);

      switch (this.selectedStockLevel) {
        case "All Stock Levels":
          // do nothing
          break;

        case "In Stock":
          where.stock = ["GT", 0];
          break;

        case "Out Of Stock":
          where.stock = ["LT", 1];
          break;

        default:
          break;
      }

      if (this.params.keyword) {
        where.title = ["CONTAINS", this.params.keyword];
      }

      // debugger

      // Process if anything in const 'where' has been set
      if (Object.keys(where).length > 0) {
        results = await this.adv.doSearch({
          tableName: "products",
          searchCriteria: where,
          pageIndex: currentIndex,
          resultsPerSearch: 30,
          loopThrough: true,
        });
      } else {
        // else just paginate records from beginning
        this.loadAllRecordsFromOffline();
      }
      this.searchingStatus = false;
      return results;
    },
    // closeBulkUploadModal() {
    //   this.currenrtBulkUploadStage = 0;
    //   this.bulkUploadData = null;
    //   this.bulkUploadModalCtrl = false;
    // },
    async loadAllRecordsFromOffline() {
      this.totalRecords = await this.adv.getTotalRecords("products");
      const results = await this.adv.paginateRecords({
        tableName: "products",
        pageIndex: 0,
        loopThrough: true,
        resultsPerSearch: 30, // <-- first set must be at least 30 for checkScroll() to work
      });

      this.products = results;
      this.filteredProducts = this.products;
      this.dataLoaded = true;
    },
    reSync(data) {
      console.log("resync function: ", data);
      // For single request
      if (this.isOnline) {
        this.resetDualSyncDialog();
        this.requestSync("products");
      } else {
        this.resetDualSyncDialog();
        // this.loadAllRecordsFromOffline();
      }
    },
    resetBulkUploadData() {
      this.bulkUploadData = null;
      this.currenrtBulkUploadStage = 0;
      this.bulkUploadModalCtrl = false;
    },
    selectBulkProductUploadDoc(e) {
      if (e) {
        this.bulkUploadFile = e.target.files[0];
        this.convertExcelFileToJSObjec();
      }
    },
    doSync() {
      this.$SendMessageToSW({
        EVENT_PAYLOAD: {
          BusinessId: GET_USER_BUSINESS_ID(),
          nonSyncedTable: "products_unsynced",
          tableToSync: "products",
          authToken: GET_USER_TOKEN(),
          moduleAPIEndpoint: ``,
          moduleGETAPIEndpoint: "",
          offlineKeyValue: "offline_cust_id",
        },
        EVENT_TYPE: "SYNC",
      });
    },
    convertExcelFileToJSObjec() {
      readXlsxFile(this.bulkUploadFile, { schema })
        .then(({ rows, errors }) => {
          this.bulkUploadData = rows;
        })
        .catch((error) => {
          // console.log(error.message);
          eventBus.$emit("trac-alert", {
            message:
              "Error: Data in file does not match the required template.",
          });
        });
    },
    async uploadBulkProducts() {
      // console.log(JSON.stringify(this.bulkUploadData));
      this.isLoading = true;
      this.bulkUploadRes = await this.$store.dispatch(
        "UPLOAD_BULK_PRODUCTS",
        this.bulkUploadData
      );

      if (this.bulkUploadRes.status) {
        this.currenrtBulkUploadStage += 1;
        // this.$refs['selectBulkProductUploadDoc'].value = '';
        this.resetBulkUploadData();

        eventBus.$emit("trac-alert", {
          message: `Hi ${
            GET_USER_DATA().name
          }, you can surf through other features on the app while your upload progress is completing.`,
        });
        this.$router.push({ name: "UploadedBatches" });
      } else {
        eventBus.$emit("trac-alert", {
          message: this.bulkUploadRes.message || "Error: Products failed to up",
        });
      }

      this.isLoading = false;
    },
    filterCategories(e) {
      if (e.checked) {
        if (e.value.name.toLowerCase() === "all") {
          this.selectedCategoriesFiltered = this.categories.map(
            (cat) => cat.name
          );
        } else {
          this.selectedCategoriesFiltered.push(e.value.name);
          if (
            this.selectedCategoriesFiltered.length ===
            this.categories.length - 1
          ) {
            this.selectedCategoriesFiltered.unshift("all");
          }
          // this.selectedCategoriesFiltered.push(e.value.name);
          // const allSeletionsTag = this.selectedCategories.filter(
          //   (cat) => cat === "all"
          // );
          // if (
          //   this.selectedCategoriesFiltered.length ===
          //   this.selectedCategories.length - allSeletionsTag.length
          // ) {
          //   this.selectedCategoriesFiltered.push("all");
          // }
        }
      } else {
        if (e.value.name.toLowerCase() === "all") {
          this.selectedCategoriesFiltered.splice(
            0,
            this.selectedCategoriesFiltered.length
          );
        } else {
          this.selectedCategoriesFiltered = this.selectedCategoriesFiltered
            .filter((cat) => cat !== e.value.name)
            .map((cat) => cat);

          if (
            this.selectedCategoriesFiltered.length ===
            this.categories.length - 1
          ) {
            this.selectedCategoriesFiltered.shift();
          }
          // this.selectedCategoriesFiltered =
          //   this.selectedCategoriesFiltered.filter((cat) => cat !== "all");
          // this.selectedCategoriesFiltered =
          //   this.selectedCategoriesFiltered.filter(
          //     (cat) => cat !== e.value.toLowerCase()
          //   );
        }
      }
    },
    checkScroll(e) {
      const myDiv = e.target;
      const shownProductsSofar = this.showingProducts;
      const continuePaginating =
        this.products.length < this.totalRecords &&
        this.page < this.adv.numberOfPages - 1;
      const hasScrolledOver =
        myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight;
      const hasDoneAnySearch = this.params.keyword || this.doneASearch;

      if (hasScrolledOver) {
        if (hasDoneAnySearch) {
          // paginate by filter
          if (continuePaginating) {
            this.doFilter(this.page).then((results) => {
              this.products = [].concat(this.products, results);
              this.page++;
            });
          }
        } else {
          // paginate
          if (continuePaginating) {
            this.adv
              .paginateRecords({
                tableName: "products",
                pageIndex: this.page,
                loopThrough: false, // will only load next set of results at a time
                resultsPerSearch: 30,
              })
              .then((fetched) => {
                this.products = [].concat(this.products, fetched);
                // debugger
                this.page++;
              });
          }
        }
      }
    },
    selectStockLevel(value) {
      const vm = this;
      function filterByStockLevel(level) {
        vm.params.stockLevel = level;
      }

      switch (value) {
        case "All Stock Levels":
          // do nothing
          filterByStockLevel("");
          break;

        case "In Stock":
          filterByStockLevel("in-stock");
          break;

        case "Out Of Stock":
          filterByStockLevel("out-of-stock");
          break;

        default:
          break;
      }

      // this.params.stockLevel = value
    },
    async searchSelectedStockLevel() {
      this.params = {
        ...this.params,
        page: 1,
        limit: 20,
      };
      this.searchingStatus = true;
      await this.loadProducts();
      this.searchingStatus = false;
    },
    inStockProducts(product) {
      if (product.stock > 0 && product.manage_stock) {
        return product;
      }
    },
    outOfStock(product) {
      if (product.stock === 0 && product.manage_stock) {
        return product;
      }
    },
    selectCategory(value) {
      if (value === "Select Category") {
        this.filteredProducts = this.products;
      } else {
        this.filteredProducts = this.products.filter(
          (product) => product.category.toLowerCase() === value.toLowerCase()
        );
      }
    },
    filterBySearch(e) {
      const value = e.target.value;

      this.searchedData = value;
      this.filteredProducts = this.products.filter((product) => {
        return product.title.toLowerCase().includes(value.toLowerCase());
      });
    },
    gotoProductsInputPage() {
      this.$router.push({ name: "AddProduct" });
    },
    gotoProductsDetailsPage(product) {
      if (
        !permissionsModules[4]["parent"]["permissionsForUser"]["editProduct"]
      ) {
        eventBus.$emit("trac-alert", {
          message: "You have the permission to edit products.",
        });
        return;
      }
      // SAVE_LOCAL_DB_DATA("product-details", product);
      this.selectAction("edit", product);
    },
    toggleDeleteModal(product) {
      this.deleteModal = true;
      this.productDeleted = false;
      this.productToDelete = product;
    },
    async fetchAllProducts() {
      await this.$store.dispatch("FETCH_ALL_PRODUCTS");
      const res = this.$store.getters["GET_ALL_PRODUCT"];

      if (res.status) {
        this.products = res.items || [];
        this.filteredProducts = this.products;
      } else {
        // alert(res.message || "Error: Error with network.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Error with network.",
        });
      }
    },
    async fetchAllCategories() {
      await this.$store.dispatch("FETCH_ALL_CATEGORIES");
      const res = this.$store.getters["GET_ALL_CATEGORIES"];

      if (res.status) {
        const data = res.data || [{ name: "", count: 0 }];
        if (data.length > 0) {
          this.categories = data.slice(1, data.length);
          this.categories.unshift({ name: "all", count: 0, _id: "all" });
          this.selectedCategoriesFiltered = this.categories.map(
            (cat) => cat.name
          );
          // this.selectedCategories = this.categories.map((cat) => {
          //   return {
          //     ...cat,
          //     _id: cat.name.toLowerCase(),
          //     name: cat.name.toLowerCase(),
          //   };
          // });
        }
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    async deleteProduct(product) {
      // const confirm = window.confirm(
      //   "Are you sure you want to delete this invoice?"
      // );

      if (confirm) {
        this.isLoading = true;
        const res = await this.$store.dispatch("DELETE_PRODUCT_V2", product.id);

        if (res.status) {
          this.loadProducts();
          this.productDeleted = true;
          // Refresh page
          // window.location.reload();
          this.requestSync("products");
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.button-style {
  background: linear-gradient(318.39deg, #0143aa 8.55%, #003283 90.68%);
}
</style>
